.sidebar-wrapper {
  background-color: #191414;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: x-large;
  color: white;
  padding: 1em;
  text-align: end;
  place-content: space-between;
}

.sidebar-img {
  width: 4rem;
  border-radius: 2rem;
}

.sidebar-selected {
  font-weight: bold;
  text-align: center;
  color: #1db954;
}

.sidebar-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sidebar-title {
  margin: 0;
  font-weight: 800;
}

.sidebar-text {
  width: 100%;
  text-align: center;
  font-weight: lighter;
  font-size: medium;
  margin: 0;
}

.sidebar-unselected {
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.sidebar-unselected:hover {
  color: #1db954;
}
